<template>
  <span class="tags">
    <el-tag v-for="tag in value" :key="tag"
            :disable-transitions="false"
            closable
            @close="handleClose(tag)">
      {{ tag }}
    </el-tag>
    <el-input
        v-if="inputVisible"
        ref="saveTagInput"
        v-model="inputValue"
        class="input-new-tag"
        size="small"
        @blur="handleInputConfirm"
        @keyup.enter.native="handleInputConfirm"/>
    <el-button v-else class="button-new-tag" size="mini" @click="showInput"> + 新增 </el-button>

  </span>
</template>

<script>
export default {
  props: {
    value: Array
  },
  model: {
    prop: 'value',
    event: 'change'
  },

  data() {
    return {
      inputVisible: false,
      inputValue: ''
    }
  },
  mounted() {
    if (!this.value) {
      this.$emit('change', [])
    }
  },
  computed: {},
  methods: {
    handleClose(tag) {
      this.$emit('change', this.value.filter(v => v !== tag))
    },

    showInput() {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm() {
      if (this.inputValue) {
        this.$emit('change', [...(this.value || []), this.inputValue])
      }
      this.inputVisible = false
      this.inputValue = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin: 0 10px;
  height: 28px;
  line-height: 26px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>

