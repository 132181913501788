<template>
  <div class="page form" style="max-width: 100%">
    <h2 v-if="!id">新建商品</h2>
    <h2 v-else>
      {{ `编辑商品：${ form.title }` }}
      <copy-link :url="'/pages/shop/goods?id=' + form.id"/>
    </h2>

    <section>
      <h3>商品信息</h3>

      <el-form class="form" ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="商品名称" prop="title" required>
          <el-input v-model="form.title"/>
        </el-form-item>
        <el-form-item label="售卖类型">
          <el-select v-model="form.sell_type" placeholder="选择类型">
            <el-option v-for="t in ['正常', '秒杀', '团购', '预售']" :key="t" :label="t" :value="t"/>
          </el-select>
        </el-form-item> 
        <el-form-item label="发货时间" v-show="form.sell_type=='预售'">
          <el-date-picker
              v-model="form.express_time"
              type="date" value-format="yyyy-MM-dd"
              placeholder="选择发货日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="售卖状态">
          <el-select v-model="form.status" placeholder="选择类型">
            <el-option v-for="t in ['正常', '已下架', '隐藏']" :key="t" :label="t" :value="t"/>
          </el-select>
          <div class="tip">设为和下架状态后，无法再购买。设为隐藏状态后，可通过链接购买。</div>
        </el-form-item>
        <!--        <el-form-item label="付款类型">-->
        <!--          <el-select v-model="form.sell_type" placeholder="选择类型">-->
        <!--            <el-option v-for="t in ['微信支付', '积分', '兑换码']" :key="t" :label="t" :value="t"/>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="商品类型">-->
        <!--          <el-select v-model="form.goods_type" placeholder="选择类型">-->
        <!--            <el-option v-for="t in ['实物商品', '虚拟物品', '会员']" :key="t" :label="t" :value="t"/>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
        <el-form-item label="信息复用操作" v-if="!id && ['秒杀', '团购'].includes(form.sell_type)">
          <el-select size="small" v-model="selected_id" filterable @change="onSelectGoods">
            <el-option v-for="u in goods_options" :label="u.title" :value="u.xid"
                       :key="u.xid"/>
          </el-select>
          <div class="tip">点击提交之后即可创建规格和库存信息</div>
        </el-form-item>
        <el-form-item label="快递类型">
          <el-select v-model="form.express_type" placeholder="选择类型">
            <el-option v-for="t in $dbus.config.express_options" :key="t" :label="t" :value="t"/>
          </el-select>
        </el-form-item>
        <el-form-item label="快递费用" v-if="form.express_type === '中通寄付'">
          <el-input-number v-model="form.express_fee"/>
        </el-form-item>
        <el-form-item label="售价" prop="title" required>
          <el-input v-model="form.price_normal" @change="onChangePrice">
            <template slot="prepend">微信支付 ￥</template>
          </el-input>
          <div class="tip">如果增加了商品规格项，则以规格单项价格为准</div>
        </el-form-item>
        <el-form-item label="会员价">
          <el-input v-model="form.price_vip">
            <template slot="prepend">微信支付 ￥</template>
          </el-input>
          <div class="tip">此会员价为会员购买时显示的价格。为 0 时表示不设置会员专享价格。如有需要，手动请修改</div>
        </el-form-item>
        <el-form-item label="积分最多可用">
          <el-input-number class="input-short" v-model="form.point_available"/>
          点
        </el-form-item>
        <el-form-item label="商品操作">
          <el-checkbox label="推荐" v-model="form.is_recommend"/>
          <el-checkbox label="置顶" v-model="form.is_top"/>
        </el-form-item>
        <el-form-item label="商品页排序">
          <el-input-number v-model="form.i_order" :min="0"/>
          <div class="tip">数值越大，排序越靠前。</div>
        </el-form-item>
        <el-form-item label="人数限制" v-if="form.sell_type === '团购'">
          <el-input-number v-model="form.group_num"/>
          人
          <div class="tip" v-if="form.sell_type === '团购'">人数小于设定，则所有人的订单自动取消。大于设定人数，则订单有效</div>
        </el-form-item>
        <el-form-item label="时间限制" v-if="!id && ['秒杀', '团购', '预售'].includes(form.sell_type)">
          <el-date-picker
            v-model="form.datetime_available_range"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
          <div class="tip">时间范围内售完为止，商品自动下架</div>
        </el-form-item>

        <el-form-item label="商品封面">
          <input-image-multi v-model="form.cover"/>
        </el-form-item>
        <el-form-item label="介绍视频">
          <video-comp v-model="form.video_id"/>
        </el-form-item>
        <el-form-item label="商品详情">
          <text-editor class="editor" v-model="form.content"/>
          <div class="tip">插图推荐尺寸宽度小于700</div>
        </el-form-item>
        <el-form-item label="重要通知">
          <text-editor class="editor" v-model="form.content_tip"/>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit" :loading="loading2">提交</el-button>
          <el-button @click="load">刷新</el-button>
          <a class="el-button" target="_blank"
             :href="`${$settings.host}/goods/get_qrcode_img?id=${id}`">二维码</a>
        </el-form-item>
      </el-form>
    </section>

    <section v-if="id">
      <h3>
        规格
        <el-button-group style="margin-left: 20px">
          <el-button size="mini" @click="onCreateSpecLabel"> 添加层级</el-button>
          <el-button size="mini" @click="onResetSpecLabel"> 重置层级</el-button>
          <el-button size="mini" type="primary" @click="onCreateSpec"> 添加规格</el-button>
        </el-button-group>
      </h3>

      <div>
        <ul style="margin: 10px">
          <li :key="c.label" v-for="(c, k) in spec_labels" style="margin: 10px">
            <span style="margin: 0 10px; font-weight: 500; cursor: pointer"
                  @click="() => onChangeSpecLabel(k)" v-text="c.label || '默认'"/>
            <input-tags v-model="c.options" @change="v => onChangeSpec2(k, v)"/>
            <!--                          <el-button size="mini" @click="onRemoveSpecLabel(k)">删除本层类别</el-button>-->
          </li>
        </ul>
      </div>
      <el-table :data="spec_options" border style="width: 100%; margin-top: 20px">
        <el-table-column v-for="(c, k) in spec_labels" :key="c.label" :prop="k" :label="c.label">
          <template slot="header" slot-scope="scope" style="cursor: pointer">
            <el-tooltip class="item" effect="dark" content="点击修改" placement="top">
              <span style="cursor: pointer" @click="() => onChangeSpecLabel(k)" v-text="c.label || '默认'"/>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="price_normal" label="价格-普通" width="100"
                         :formatter="({price_normal}) => `￥${price_normal}`"/>
        <el-table-column prop="price_vip" label="价格-会员" width="100"
                         :formatter="({price_vip}) => `￥${price_vip}`"/>
        <el-table-column prop="stock_num" label="库存" width="110"/>
        <el-table-column align="right">
          <template slot="header" slot-scope="scope">
          </template>
          <template slot-scope="scope">
            <el-button-group>
              <el-button size="mini" @click="onDeleteSpec(scope.row)" type="danger">删除</el-button>
              <!--              todo 添加编辑功能-->
              <el-button size="mini" @click="onEditSpec(scope.$index, scope.row)">编辑</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </section>

    <!--编辑规格对话框-->
    <el-dialog class="dialog-form"
               :title="formSpec.id? '编辑规格':'新增规格'" :show-close="false"
               :visible.sync="showSpecDialog"
               width="400px">
      <el-form class="_form" size="small" label-width="70px">
        <el-form-item :label="c.label" v-for="(c, k) in spec_labels" :key="k">
          <el-select v-model="formSpec[k]" clearable placeholder="请选择规格">
            <el-option v-for="o in spec_labels[k].options"
                       :key="o" :label="o" :value="o"/>
          </el-select>
          <!--          todo 上一级为空的时候下一级禁止选择-->
        </el-form-item>

        <el-form-item label="库存数量">
          <el-input-number v-model="formSpec.stock_num" clearable/>
          <div class="tip">可选</div>
        </el-form-item>
        <el-form-item label="正常价格">
          <el-input class="input-short-2" v-model="formSpec.price_normal">
            <span slot="prepend">微信支付</span>
            <span slot="append">元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="VIP价格">
          <el-input class="input-short-2" v-model="formSpec.price_vip">
            <span slot="prepend">微信支付</span>
            <span slot="append">元</span>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseSpec">取 消</el-button>
        <el-button type="primary" @click="onChangeSpec"> 提交 </el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import InputTags from "../core/components/form/input-tags"

  export default {
    components: {InputTags},
    data() {
      return {
        id: this.$route.query.id,
        loading: false,
        loading2: false,
        showSpecDialog: false,
        form: {},
        rules: {
          title: [
            {required: true, message: '请输入标题', trigger: 'blur'}
          ]
        },

        goods_options: [],
        selected_id: '',
        spec_labels_show: false,
        spec_labels: {},
        spec_options: [],
        formSpec: {},
      }
    },
    mounted() {
      this.load()
    },
    methods: {
      async load() {
        if (this.id) {
          this.$api.request('/goods/get', {id: this.id, show_all: true}).then(data => {
            this.form = data
            this.spec_labels = data.spec_labels
            this.spec_options = data.spec_options
          })
        }
        this.goods_options = await this.$api.request(
          '/list_available_objects', {model: 'goods', simple: false})
      },
      onChangeSpec2(k, v) {
        // todo 添加规格信息，自动同步到 spec_options ，而不是只增加候选项
        // k 是指的 spec_i   v 是指的 options
        // 若要避免直接修改 spec_labels 可将 v-model="c.options" 改为 :value="c.options"
        // let options = this.spec_labels[k].options
        //
        // if (options.length > v.length) {
        //   console.log('remove')
        // } else {
        //   console.log('add')
        //   // 添加项
        //   let changed = v.filter(o => !options.includes(o))
        //   if (!changed.length) {
        //     return
        //   }
        //
        // }
        this.onSubmitSpec()
      },
      onSelectGoods(v) {
        for (let i = 0; i < this.goods_options.length; i++) {
          let g = this.goods_options[i]
          if (v === g.xid) {
            delete g.obj.id
            this.form = {
              ...g.obj,
              title: `【${this.form.sell_type}】${g.obj.title}`,
              sell_type: this.form.sell_type
            }
          }
        }
        setTimeout(() => {
          window.scrollTo(0, 0)
        }, 500)
      },
      onSubmit() {
        this.$refs.form.validate(valid => {
          if (!valid) {
            return this.$message.error('请先填写必须项')
          }
          this.loading2 = true
          const url = this.id ? '/goods/update_goods_info' : '/goods/create_goods'
          this.$api.request( url ,
            {
              id: this.id,
              ...this.form,
              price_normal: this.form.price_normal || 0,
              price_vip: this.form.price_vip || 0,
              datetime_created:'2023-01-01 10:00:00',
              datetime_updated:'2023-01-01 10:00:00',
            }
          ).then(data => {
            this.form = data
            this.loading2 = false
            this.$message.success('更新成功!')
            this.$router.push({query: {id: data.id}})
          }).catch(() => {
            this.loading2 = false
          })
        })
      },
      onCreateSpecLabel() {
        this.$prompt('请输入规格层级名称').then(({value}) => {
          for (let i = 1; i < 5; i++) {
            if (!this.spec_labels[`spec_${i}`] || this.spec_labels[`spec_${i}`].label === '默认') {
              this.spec_labels = {...this.spec_labels, [`spec_${i}`]: {label: value, options: []}}
              this.onSubmitSpec()
              return
            }
          }
        })
      },
      onChangeSpecLabel(k) {
        this.$prompt('请输入名称').then(({value}) => {
          this.spec_labels[k].label = value
          this.onSubmitSpec()
        })
      },
      onRemoveSpecLabel(k) {
        this.$confirm('您确认删除该层级，并删除其下的规格吗？').then(() => {
          // this.spec_labels = {spec_1: {label: '默认', options: []}}
          // this.spec_options = []
          // this.onSubmitSpec()
        })
      },
      onResetSpecLabel(k) {
        this.$confirm('您确认重置层级并删除所有规格吗？').then(() => {
          this.spec_labels = {spec_1: {label: '默认', options: []}}
          this.spec_options = []
          this.onSubmitSpec()
        })
      },
      onCreateSpec() {
        this.showSpecDialog = true
        this.formSpec = {
          price_normal: this.form.price_normal,
          price_vip: this.form.price_vip,
        }
      },
      onChangeSpec() {
        if (this.spec_options[this.formSpec.i]) {
          this.spec_options[this.formSpec.i] = this.formSpec

        } else {
          this.spec_options.push(this.formSpec)
        }
        this.onSubmitSpec()
        this.onCloseSpec()
      },
      onSubmitSpec() {
        this.$api.request('/goods/update_goods_info', {
          id: this.id,
          spec_labels: this.spec_labels,
          spec_options: this.spec_options,
        }).then(data => {
          this.load()
          this.showSpecDialog = false
          this.formSpec = {}
          this.$message.success('添加成功')
        })
      },
      onEditSpec(i, row) {
        this.showSpecDialog = true
        this.formSpec = {i, ...row}
      },
      onCloseSpec() {
        this.showSpecDialog = false
        this.formSpec = {}
      },
      onDeleteSpec(row) {
        this.$confirm('您确认要删除吗？').then(() => {
          this.spec_options = this.spec_options.filter(r => JSON.stringify(r) !== JSON.stringify(row))
          this.onSubmitSpec()
        })
      },
      onChangePrice(v) {
        this.form = {...this.form, price_vip: (v * 0.7).toFixed(1)}
      }
    }
  }
</script>
